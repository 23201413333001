* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.side-bar {
  width: 20vw;
  height: 100vh;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-bar > div > ul > li {
  list-style: none;
  padding: 15px 25px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  gap: 20px;
}

.side-bar > div > ul > li:hover {
  background-color: teal;
  color: #fff;
}

.flex-1 {
  display: flex;
}

.side-bar > div > div > img {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  /* margin: /20px; */
}

.side-bar > div > ul > li.active {
  background-color: teal;
  color: #fff;
}

.capitalize {
  text-transform: capitalize;
}

.main {
  background-color: #f3f3f3;
}

.menu-card-list {
  width: 80vw;
  height: 100vh;
  padding: 15px 25px;
  overflow-y: auto;
}

.custom-input {
  border: none;
  background: #fff;
  outline: none;
  margin-left: 5px;
  width: 100%;
}

.custom-input-error {
  border: 1px solid red !important;
}

.custom-input-wrapper {
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.my-header {
  /* background: blue; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.my-card {
  height: 210px;
  width: 170px;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: auto;
}

/* Edit scroll for my-card */
.my-card::-webkit-scrollbar {
  width: 0.2em;
}

.my-card::-webkit-scrollbar-thumb {
  background-color: #ececec;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.my-card:hover {
  width: 175px;
  height: 215px;
  color: teal !important;
  border: 2px solid teal;
}

.small-text {
  font-size: 13px;
}

.my-card > span {
  font-size: 50px;
}

.my-card > h6 {
  font-weight: 600;
}

.space-between {
  justify-content: space-between;
}

.my-card > head > div {
  display: flex;
  background: white;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.gap-1 {
  gap: 10px;
}

.justify-end {
  justify-content: flex-end;
}

.sales-container {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-bottom: 20px;

  height: 65vh;
  overflow: auto;
}

.sales-section {
  /* padding: 20px; */
  width: 100%;
  height: 90vh;
  background: #fff;
  border-radius: 10px;
  /* padding: 15px; */
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.sales-header {
  height: 10vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.main-body {
  height: 90vh;
  overflow: auto;
}

.sales-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}
.full-width {
  width: 100%;
}

.p-2 {
  padding: 10px;
}

.p-1 {
  padding: 5px;
}

.pointer {
  cursor: pointer;
}

.margin-0 {
  margin: 0;
}

.custom-dropdown {
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow: auto;
  transition: all 0.2s ease;
  display: none;
}

.relative {
  position: relative;
}

.priceModeSwitch {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.priceModeSwitch > button {
  background: #fff;
  border: none;
  outline: none;
  padding: 25px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100px;
}

.priceModeSwitch > button:hover {
  background: #f3f3f3;
}

.my-active-switch {
  background: #f3f3f3 !important;
  color: teal !important;
  font-weight: 600;
}

.tabs .indicator {
  background-color: teal;
}

.tabs .tab a {
  color: #000;
}

.tabs .tab a:hover {
  color: teal;
}

.tabs .tab a.active {
  color: teal;
}

.sales-main {
  background: #f3f3f3;
  padding: 15px;
  height: 88%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.sales-tab {
  height: calc(90vh - 48px) !important;
  padding: 10px;
  /* overflow: auto; */
  /* background: red */
}

.sales-tab-header {
  height: 12%;
}

.sales-main > section {
  height: 100%;
  width: 66%;
  /* background-color: red; */
  overflow: auto;
  padding: 5px;
}

.sales-main > aside {
  height: 100%;
  width: 30%;
}

.product-card {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px 25px;
  border-radius: 10px;
  gap: 15px;
}

.product-card > .product-details {
  width: 30% !important;
  display: flex;
  gap: 20px;
  align-items: center;
}

.sales-subtotal {
  padding: 25px;
  border-radius: 10px;
  background: #fff;
  margin-top: 2px;
}

.bold {
  font-weight: 600;
}

.text-hover:hover {
  color: teal;
  cursor: pointer;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
}

.btn-large {
  box-shadow: none;
  border-radius: 5px;
}

.btn-large:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important;
}

.btn {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 0.4em;
}

::-webkit-scrollbar-thumb {
  background-color: teal;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.custom-text-input {
  border: 1px solid #ccc;
  background: #fff;
  outline: none;
  width: 500px;
  padding: 15px;
  border-radius: 5px;
  margin-top: 5px;
}

.custom-text-input:focus {
  border: 1px solid teal;
}

.custom-text-input::placeholder {
  color: #999 !important;
}

.custom-select {
  border: 1px solid #ccc;
  background: #fff;
  outline: none;
  width: 500px;
  border-radius: 5px;
  margin-top: 5px;
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.login-container > form {
  width: 30%;
  height: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container > form > div {
  width: 100%;
}

.login-container input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  outline: none;
}

.box {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

tr {
  border-bottom: 1px solid #fff !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.b-d-container > section {
  width: 70%;
  height: 100%;
  overflow: auto;
}

.b-d-container > aside {
  width: 30%;
  height: 100%;
}

.rounded {
  border-radius: 10px;
}

.d-card {
  height: 150px;
  width: 33%;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.datepicker {
  height: 30px;
  padding: 10px;
  color: #000 !important;
  border-radius: 5px;
  border: 1px solid #ddd;
  max-width: 100px;
}

.datepicker input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.datepicker input::placeholder {
  color: #999;
  font-size: 12px !important;
}

.hoverable:hover {
  background: #f3f3f3;
  cursor: pointer;
}

.highlighted {
  background: #ececec !important;
}
.align-end {
  align-items: flex-end;
}

.ml-2 {
  margin-left: 20px;
}

.terminal {
  /* width: 80mm; */
  border-radius: 5px;
  margin: 0;
  box-shadow: none;
}

.date-input {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.date-input input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.date-input > .input-field {
  margin: 0 !important;
}

.sr-container {
  height: 100vh;
  /* overflow: hidden; */
  /* background-color: black; */
}

.sr-container article {
  height: calc(100vh - 10vh);
  display: flex;
  /* border: 2px solid red; */
}

.sr-container > article > section {
  width: 75%;
  height: 100%;
  overflow: auto;
  /* background: red; */
}

.sr-history {
  width: 25%;
  height: 100%;
  overflow: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* margin-left: 10px; */
  padding: 25px;
}

.sales-recipient {
  height: 100%;
  background: #f3f3f3;
  border-radius: 10px 0 0 10px;
  padding: 15px;
}

.bordered {
  border: 1px solid #ccc;
  border-radius: 5px;
}

fieldset {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.product-card-mobile {
  display: none;
}

.hide-on-print {
  display: hidden;
}

.A4 {
  padding: 20px;
  width: 210mm;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.sales-type-chip {
  background: orange;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  font-size: 12px;
  font-weight: 600;
}

.daily-total-sales-container {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  height: 80vh;
  overflow: auto;
}

.border {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
}

.rectangular-section {
  border-radius: 5px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

.rectangular-section > main {
  max-height: 50vh;
  overflow: auto;
  padding: 15px;
}

.text-center {
  text-align: center;
}

@media print {
  body * {
    visibility: hidden;
  }

  .A4 * {
    visibility: visible;
    box-shadow: none;
  }

  .terminal * {
    visibility: visible;
    box-shadow: none;
  }

  td {
    padding: 1px;
  }

  tr {
    border-bottom: 1px solid #888 !important;
  }

  /* .hide-on-print {
    display: none !important;
  } */
}

@media screen and (max-width: 768px) {
  .login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-container > form {
    width: 90%;
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-container > form > div {
    width: 100%;
  }

  .custom-text-input {
    width: 100%;
  }

  .product-card {
    flex-wrap: wrap;
    gap: 10px;
    background: #f3f3f3;
  }

  .sales-main {
    flex-direction: column;
    /* background: blue; */
    height: 100%;
  }

  .sales-main > section {
    width: 100%;
    height: 64%;
    background-color: #fff;
    /* background-color: green; */
    /* border: 4px solid red; */
    /* display: none; */
    /* margin-bottom: 1%; */
  }

  .sales-main > aside {
    /* border: 4px solid blue; */
    width: 100%;
    height: 45%;
    overflow: auto;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); */
    /* padding: 25px; */
  }

  .sales-subtotal {
    /* padding: 10px; */
    border-radius: 10px;
    background: #fff;
  }

  .sales-subtotal h6 {
    font-size: 15px;
    margin: 0;
  }

  .sales-subtotal h5 {
    font-size: 20px;
    margin: 0;
  }

  .sales-recipient {
    display: none;
  }

  .select-supplier-input {
    /* width: 100%; */
    display: none;
  }
  .product-card {
    display: none;
  }

  .product-card-mobile {
    display: block;
    padding: 15px;
    margin-bottom: 10px;
    background: #f3f3f3;
    width: 100%;
    border-radius: 10px;
  }
}
